<script>
import Layout from '@/components/layout/Layout'
import Menu, {usersState} from './menu'
import MenuOptions from '@/components/layout/context-menu/context-window-options'
import DefaultMixin from './mixin'
import {list as listGroups, acl} from "@/domain/usuario/services/groups"

export default {
  name: 'UsuariosPage',
  provide: function () {
    return {
      userPage: this
    }
  },
  mixins: [DefaultMixin],
  components: {
    Layout
  },
  data () {
    return {
      loading: true,
      groups: [],
      acl: []
    }
  },
  computed: {
    menu () {
      return Menu
    },
    menuDashOptions () {
      return MenuOptions
    }
  },
  mounted () {
    this.rotinas()
  },
  destroyed () {
  },
  methods: {
    async rotinas () {
      this.loading = true
      await listGroups(100, 1, '')
          .then(response => {
            this.groups = response.data.result
            usersState.groups = response.data.total
          })
          .catch(error => {
            this.alertApiError(error)
          })
      this.acl = await acl()
      this.$nextTick(() => {
        this.loading = false
      })
    },
  },
  meta: {
    title: 'Usuários',
    name: 'Usuarios'
  }
}
</script>

<template>
  <layout :menu="menu" :folded="false" no-title hfull>
    <router-view v-if="!loading" />
  </layout>
</template>
